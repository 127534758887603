import * as React from "react";

const NextButton = (props) => (
  <svg viewBox="0 0 150 96" fill="none">
    <path
      d="M147.75 47.7273C147.75 59.8707 140.011 71.1862 126.825 79.5773C113.666 87.9514 95.3489 93.2045 75 93.2045C54.6511 93.2045 36.3343 87.9514 23.175 79.5773C9.98893 71.1862 2.25 59.8707 2.25 47.7273C2.25 35.5838 9.98893 24.2683 23.175 15.8772C36.3343 7.50311 54.6511 2.25 75 2.25C95.3489 2.25 113.666 7.50311 126.825 15.8772C140.011 24.2683 147.75 35.5838 147.75 47.7273Z"
      className="btn-stroke"
      strokeWidth="4.5"
    />
    <path
      d="M31.7755 36.01V61H36.9205V44.27H36.9905L47.3855 61H52.8805V36.01H47.7355V52.775H47.6655L37.2355 36.01H31.7755ZM57.0179 36.01V61H75.9879V56.38H62.5129V50.255H74.6229V45.985H62.5129V40.63H75.7079V36.01H57.0179ZM85.1382 47.91L76.4232 61H82.5832L88.1482 52.355L93.6082 61H100.153L91.4382 47.945L99.4532 36.01H93.4332L88.3582 43.99L83.4582 36.01H77.0882L85.1382 47.91ZM107.188 40.63V61H112.683V40.63H120.173V36.01H99.698V40.63H107.188Z"
      className="btn-fill"
    />
  </svg>
);

export default NextButton;
